import './style.css';
import { UlaService } from './services/ulaService';
import { Logger } from './utils/logger';
import { getConfig, setConfig } from './config.ts';

const loadConfig = async (): Promise<void> => {
  const nodeEnv = import.meta.env.MODE;
  if (nodeEnv === 'development') {
    Logger.logInfo(
      'Starting app in development mode with config from .env',
      getConfig(),
    );
    return;
  }

  try {
    Logger.logInfo(
      'Starting app in production mode with config from /config request',
    );
    const response = await fetch('/config');
    const config = await response.json();
    setConfig(config);
    Logger.logInfo('-> Config fetched from server', getConfig());
  } catch (error) {
    Logger.logError('Failed to fetch config from server:', error);
    throw error;
  }
};

// Start the application
loadConfig().then(() => {
  const app = UlaService.getInstance();
  app.initialize().catch((error) => {
    Logger.logError('Application failed to start:', error);
  });
});
