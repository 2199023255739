import { isTopApp } from './isTopApp.ts';

type LogArgument =
  | string
  | number
  | boolean
  | null
  | undefined
  | object
  | unknown;

export class Logger {
  private static getMessage(args: LogArgument[]): LogArgument[] {
    const mode = isTopApp() ? 'APP' : 'IFRAME';
    return [`🔒[ULA][${mode}]`, ...args];
  }

  static logInfo(...args: LogArgument[]) {
    console.log(...this.getMessage(args));
  }

  static logError(...args: LogArgument[]) {
    console.error(...this.getMessage(args));
  }

  static logWarn(...args: LogArgument[]) {
    console.warn(...this.getMessage(args));
  }

  static logDebug(...args: LogArgument[]) {
    console.debug(...this.getMessage(args));
  }
}
