import { ApplicationDto } from '../types/ApplicationDto';
import { ConfigDto } from '../types/ConfigDto';
import { ApiService } from './apiService';
import { getConfig } from '../config';
import { ConfigError } from '../errors/ConfigError';
import { isEmpty } from 'validator';

export class ConfigService {
  private static instance: ConfigService;
  private readonly defaultOrganization = 'TF';
  private readonly defaultApplication = 'ERP';
  private readonly allowedOrigins: string[] = [];
  private firebaseConfigs?: ConfigDto[];
  private applications?: ApplicationDto[];
  private activeApplication: string = this.defaultApplication;
  private organizationCode: string = this.defaultOrganization;
  private apiService!: ApiService;

  public readonly loginPermission = 'LOGIN';

  private constructor() {
    this.initialize();
    this.loadAllowedOrigins();
  }

  public static getInstance(): ConfigService {
    if (!ConfigService.instance) {
      ConfigService.instance = new ConfigService();
      // Initialize ApiService with this instance
      ApiService.initialize(ConfigService.instance);
      ConfigService.instance.apiService = ApiService.getInstance();
    }
    return ConfigService.instance;
  }

  public getActiveApplication(): string {
    if (!this.applications) {
      return this.activeApplication;
    }

    const availableApps = this.getAvailableApps();
    if (!availableApps) {
      return this.activeApplication;
    }

    const activeAppNames = availableApps.map((app) => app.name);
    if (!activeAppNames.includes(this.activeApplication)) {
      this.activeApplication = availableApps[0].name;
    }

    return this.activeApplication;
  }

  public getOrganizationCode(): string {
    return this.organizationCode;
  }

  public getActivePermissionPrefix(): string | undefined {
    return this.getAvailableApps()?.find(
      (app: ApplicationDto) => app.name === this.getActiveApplication(),
    )?.permissionPrefix;
  }

  public getAvailableApps(): ApplicationDto[] | null {
    if (!this.applications) {
      return null;
    }

    return this.applications.filter((app: ApplicationDto) =>
      app.allowedTenants.includes(this.getOrganizationCode()),
    );
  }

  public setActiveApplication(application: string): void {
    this.activeApplication = application;
  }

  public setOrganizationCode(code: string): void {
    this.organizationCode = code;
  }

  public getAllowedOrigins(): readonly string[] {
    return Object.freeze([...this.allowedOrigins]);
  }

  public async getConfig(): Promise<ConfigDto> {
    if (!this.getOrganizationCode()) {
      throw new ConfigError('Organization code not defined');
    }

    if (!this.firebaseConfigs) {
      await this.loadConfigurations();
    }

    const config = this.firebaseConfigs?.find(
      (config) => config.organization.code === this.getOrganizationCode(),
    );

    if (!config) {
      throw new ConfigError(
        `No valid firebase configs found for organization ${this.getOrganizationCode()}`,
      );
    }

    return config;
  }

  public async getFirebaseConfigs(): Promise<ConfigDto[]> {
    if (!this.firebaseConfigs) {
      await this.loadConfigurations();
    }
    return [...this.firebaseConfigs!];
  }

  public async getApps(): Promise<ApplicationDto[]> {
    if (!this.applications) {
      await this.loadConfigurations();
    }
    return [...this.applications!];
  }

  private initialize(): void {
    const params = new URLSearchParams(window.location.search);
    const urlApplication = params.get('application');
    if (this.isEmptyQueryParam(urlApplication)) {
      this.setActiveApplication(urlApplication ?? this.defaultApplication);
    }
    const urlOrganizationCode = params.get('organization');
    if (this.isEmptyQueryParam(urlOrganizationCode)) {
      this.setOrganizationCode(urlOrganizationCode);
    }

    // Clear local storage regarding to consultation with @vaclav.miculka
    localStorage.removeItem('activeApplication');
    localStorage.removeItem('organizationCode');
  }

  private isEmptyQueryParam(str: string | null): str is string {
    return str !== null && !isEmpty(str);
  }

  private loadAllowedOrigins(): void {
    const config = getConfig();
    this.allowedOrigins.push(config.TF_ERP_URL, config.TF_ADM_URL);
  }

  private async loadConfigurations(): Promise<void> {
    try {
      const [firebase, applications] = await Promise.all([
        this.apiService.getFirebaseConfig(),
        this.apiService.getApplicationConfig(),
      ]);

      this.firebaseConfigs = firebase;
      this.applications = applications;
    } catch (error) {
      throw new ConfigError('Failed to load configurations', error);
    }
  }
}
