import { ConfigService } from './configService';
import { getConfig } from '../config';
import { Logger } from '../utils/logger';
import { RedirectError } from '../errors/RedirectError';

export class RedirectService {
  private static instance: RedirectService;
  private readonly configService: ConfigService;

  private constructor() {
    this.configService = ConfigService.getInstance();
  }

  public static getInstance(): RedirectService {
    if (!RedirectService.instance) {
      RedirectService.instance = new RedirectService();
    }
    return RedirectService.instance;
  }

  public redirectToApp(): void {
    const redirectUrl = this.getAppUrl();
    Logger.logInfo(`Redirecting to app: ${redirectUrl}`);
    window.location.href = redirectUrl;
  }

  private getAppUrl(): string {
    const app = this.configService.getActiveApplication()?.toLowerCase();
    const config = getConfig();

    switch (app) {
      case 'erp':
        return config.TF_ERP_URL;
      case 'admin':
        return config.TF_ADM_URL;
      default:
        throw new RedirectError(`Application ${app} is not supported`);
    }
  }
}
