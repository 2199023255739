import { LoginForm } from '../components/loginForm';
import { AuthService } from './authService';
import { getRedirectResult, onIdTokenChanged } from 'firebase/auth';
import { getElement } from '../utils/getElement';
import { Logger } from '../utils/logger';
import { isTopApp } from '../utils/isTopApp';
import { MessageService } from './messageService';
import { htmlIds } from '../constants';

interface LoginFormData {
  email: string;
  password: string;
}

export class UlaService {
  private static instance: UlaService;
  private readonly authService: AuthService;
  private readonly messageService: MessageService;
  private readonly loginForm: LoginForm;
  private logoutInProgress: boolean = false;

  private constructor() {
    this.authService = AuthService.getInstance();
    this.messageService = MessageService.getInstance();
    this.loginForm = new LoginForm();
  }

  public static getInstance(): UlaService {
    if (!UlaService.instance) {
      UlaService.instance = new UlaService();
    }
    return UlaService.instance;
  }

  public async initialize(): Promise<void> {
    try {
      await this.authService.initialized;
      this.messageService.startListening();

      if (isTopApp()) {
        await this.handleTopAppInit();
      } else {
        await this.handleIframeInit();
      }
    } catch (error) {
      Logger.logError('Failed to initialize application:', error);
      throw error;
    }
  }

  private setupLoginFormListeners(): void {
    if (!isTopApp()) return;

    const signInForm = getElement<HTMLFormElement>(`#${htmlIds.loginForm}`);
    const googleLogin = getElement<HTMLButtonElement>(
      `#${htmlIds.googleLogin}`,
    );

    signInForm.addEventListener('submit', this.handleFormSubmit.bind(this));
    googleLogin.addEventListener('click', this.handleGoogleLogin.bind(this));
  }

  private async handleFormSubmit(e: SubmitEvent): Promise<void> {
    e.preventDefault();
    const form = e.target as HTMLFormElement;
    const formData: LoginFormData = {
      email: form[this.loginForm.formInputIds.email].value,
      password: form[this.loginForm.formInputIds.password].value,
    };

    try {
      this.loginForm.validateForm(formData);
      await this.authService.loginWithPassword(
        formData.email,
        formData.password,
      );
    } catch (error) {
      // Validation errors are already handled by the LoginForm class
      if (!(error instanceof Error && error.name === 'LoginFormError')) {
        throw error;
      }
    }
  }

  private async handleGoogleLogin(e: MouseEvent): Promise<void> {
    e.preventDefault();
    await this.authService.loginWithGoogle();
  }

  private async handleTopAppInit(): Promise<void> {
    const redirectResult = await getRedirectResult(
      this.authService.firebaseAuth,
    );
    Logger.logInfo('Redirect result', redirectResult);

    if (redirectResult) {
      await this.authService.handleLoginResponse(redirectResult);
    }

    const appContainer = getElement<HTMLDivElement>(`#${htmlIds.ulaApp}`);
    await this.loginForm.render(appContainer);
    this.setupLoginFormListeners();
  }

  private async handleIframeInit(): Promise<void> {
    const customToken = await this.authService.getCustomToken();
    Logger.logInfo('Custom token', customToken.data);

    if (customToken.data) {
      await this.authService.logout();
      await this.authService.loginWithCustomToken(customToken.data);
    }

    onIdTokenChanged(this.authService.firebaseAuth, async (user) => {
      Logger.logInfo('Firebase auth state changed', user);

      if (user) {
        const idToken = await user.getIdToken();
        Logger.logInfo('ID token', idToken);
        await this.authService.refreshAGWLogin(idToken);
        this.messageService.sendUserInfo();
      } else {
        Logger.logInfo('authServiceUser', this.authService.loggedUser);
        if (!this.authService.loggedUser && this.logoutInProgress) {
          Logger.logInfo('Logout is in progress. Skipping logout request...');
          return;
        }
        this.logoutInProgress = true;
        Logger.logInfo('User signed out');
        await this.authService.logout();
        Logger.logInfo(
          'authServiceUser - about to send user info',
          this.authService.loggedUser,
        );
        this.messageService.sendUserInfo();
      }
    });
  }
}
