import { isEmail, isEmpty } from 'validator';
import { ConfigService } from '../services/configService';
import { ApplicationDto } from '../types/ApplicationDto';
import { ConfigDto } from '../types/ConfigDto';
import { OrganizationDto } from '../types/OrganizationDto';
import { getElement } from '../utils/getElement';
import { htmlIds } from '../constants';

interface FormData {
  email: string;
  password: string;
}

interface ValidationError {
  field: keyof FormData;
  message: string;
}

class LoginFormError extends Error {
  constructor(public readonly errors: ValidationError[]) {
    super('Validation failed');
    this.name = 'LoginFormError';
  }
}

export class LoginForm {
  private readonly configService: ConfigService;

  constructor() {
    this.configService = ConfigService.getInstance();
  }

  private static readonly INPUT_IDS = {
    email: 'email',
    password: 'password',
  } as const;

  private static readonly ERROR_MESSAGES = {
    EMAIL_REQUIRED: 'Email is required',
    EMAIL_INVALID: 'Email must have valid format',
    PASSWORD_REQUIRED: 'Password is required',
  } as const;

  public get formInputIds(): typeof LoginForm.INPUT_IDS {
    return LoginForm.INPUT_IDS;
  }

  private getFormInputErrorElement(fieldName: keyof FormData): HTMLDivElement {
    return getElement<HTMLDivElement>(`#${fieldName}-error`);
  }

  private validateFormData(formData: FormData): void {
    const errors: ValidationError[] = [];

    if (isEmpty(formData.email)) {
      errors.push({
        field: 'email',
        message: LoginForm.ERROR_MESSAGES.EMAIL_REQUIRED,
      });
    } else if (!isEmail(formData.email)) {
      errors.push({
        field: 'email',
        message: LoginForm.ERROR_MESSAGES.EMAIL_INVALID,
      });
    }

    if (isEmpty(formData.password)) {
      errors.push({
        field: 'password',
        message: LoginForm.ERROR_MESSAGES.PASSWORD_REQUIRED,
      });
    }

    if (errors.length > 0) {
      this.displayValidationErrors(errors);
      throw new LoginFormError(errors);
    }
  }

  private displayValidationErrors(errors: ValidationError[]): void {
    // Clear all errors first
    Object.keys(LoginForm.INPUT_IDS).forEach((field) => {
      const errorElement = this.getFormInputErrorElement(
        field as keyof FormData,
      );
      errorElement.innerHTML = '&nbsp;';
      errorElement.classList.add('opacity-0');
      errorElement.classList.remove('opacity-100');
    });

    // Display new errors
    errors.forEach((error) => {
      const errorElement = this.getFormInputErrorElement(error.field);
      errorElement.innerHTML = error.message;
      errorElement.classList.add('opacity-100');
      errorElement.classList.remove('opacity-0');
    });
  }

  private getApplicationHtml(applications: ApplicationDto[]): string {
    const activeOrganizationCode = this.configService.getOrganizationCode();
    const activeApplication = this.configService.getActiveApplication();

    return applications
      .filter((app) => app.allowedTenants.includes(activeOrganizationCode))
      .map((application) => {
        const isActive = application.name === activeApplication;
        return this.renderApplicationItem(application, isActive);
      })
      .join('');
  }

  private renderApplicationItem(
    application: ApplicationDto,
    isActive: boolean,
  ): string {
    const activeClass = isActive ? ' bg-gray-200' : '';
    return `
      <div 
        class="flex flex-grow flex-col justify-center items-center text-center border-r last:border-r-0 p-3 py-1 cursor-pointer transition ease-in-out hover:bg-gray-200${activeClass}"
        id="application-${application.name}"
        data-cy="ula-application-${application.name.toLowerCase()}"
      >
        <div class="w-12 sm:w-20 rounded-full overflow-hidden">
          <img 
            src="/apps/${application.name.toLowerCase()}.svg"
            alt="${application.name} icon"
            class="w-12 sm:w-20 bg-white p-2"
          />
        </div>
        <div class="mt-1 font-semibold text-lg">${application.name}</div> 
      </div>
    `;
  }

  private getOrganizationHtml(organizations: OrganizationDto[]): string {
    const activeOrganizationCode = this.configService.getOrganizationCode();

    return organizations
      .map((organization) => {
        const isActive = organization.code === activeOrganizationCode;
        return this.renderOrganizationItem(organization, isActive);
      })
      .join('');
  }

  private renderOrganizationItem(
    organization: OrganizationDto,
    isActive: boolean,
  ): string {
    const activeClass = isActive ? ' bg-gray-200' : '';
    return `
      <div 
        class="flex flex-grow justify-start items-center sm:flex-col text-center border-b last:border-b-0 sm:border-b-0 sm:last:border-b-0 sm:border-r sm:last:border-r-0 py-2 sm:py-3 px-3 sm:px-6 cursor-pointer transition ease-in-out hover:bg-gray-200${activeClass}"  
        id="organization-${organization.code}"
        data-cy="ula-organization-${organization.code.toLowerCase()}"
      >
        <img 
          src="/tenants/${organization.code.toLowerCase()}.png"
          alt="${organization.name} logo"
          class="rounded-full max-w-12 sm:max-w-20"
        />
        <div class="ml-3 sm:ml-0 sm:mt-3 font-semibold text-2xl">${organization.code}</div> 
      </div>
    `;
  }

  private async renderApps(): Promise<void> {
    const apps = await this.configService.getApps();
    const container = getElement<HTMLDivElement>(
      `#${htmlIds.applicationsContainer}`,
    );

    container.innerHTML = this.getApplicationHtml(apps);

    container.childNodes.forEach((node) => {
      const element = node as HTMLDivElement;
      element.addEventListener('click', () => {
        const applicationName = element.id.split('-')[1];
        this.setActiveApplication(applicationName);
      });
    });
  }

  private setActiveApplication(application: string): void {
    const container = getElement<HTMLDivElement>(
      `#${htmlIds.applicationsContainer}`,
    );

    container.childNodes.forEach((node) => {
      (node as HTMLDivElement)?.classList?.remove('bg-gray-200');
    });

    getElement<HTMLDivElement>(`#application-${application}`).classList.add(
      'bg-gray-200',
    );
    this.configService.setActiveApplication(application);

    const url = new URL(window.location.toString());
    url.searchParams.set('application', application);
    window.history.pushState({}, '', url.toString());
  }

  private setActiveOrganization(organizationCode: string): void {
    const container = getElement<HTMLDivElement>(
      `#${htmlIds.organizationsContainer}`,
    );

    container.childNodes.forEach((node) => {
      (node as HTMLDivElement)?.classList?.remove('bg-gray-200');
    });

    getElement<HTMLDivElement>(
      `#organization-${organizationCode}`,
    ).classList.add('bg-gray-200');
    this.configService.setOrganizationCode(organizationCode);

    const url = new URL(window.location.toString());
    url.searchParams.set('organization', organizationCode);
    window.history.pushState({}, '', url.toString());

    this.renderApps();
  }

  private setupOrganizationListeners(): void {
    const container = getElement<HTMLDivElement>(
      `#${htmlIds.organizationsContainer}`,
    );

    container.childNodes.forEach((node) => {
      const element = node as HTMLDivElement;
      element.addEventListener('click', () => {
        const organizationCode = element.id.split('-')[1];
        this.setActiveOrganization(organizationCode);
      });
    });
  }

  private renderLoginForm(configs: ConfigDto[]): string {
    const organizations = this.getOrganizationHtml(
      configs.map((config) => config.organization),
    );

    return `
      <div class="flex justify-center items-center min-h-screen">
        <div class="max-w-screen-xl mt-6 flex flex-col justify-center items-center">
          <img src="/dbg_logo_with_text.png" alt="DBG logo" class="w-full sm:max-w-xl" />
          <div class="w-full sm:max-w-screen-xl px-3">
            <div class="flex flex-col sm:flex-row border rounded-lg" id="${htmlIds.organizationsContainer}">
              ${organizations}
            </div>
          </div>
          <div class="w-full sm:w-auto sm:max-w-md mt-3 px-3">
            <div class="border flex rounded-lg" id="${htmlIds.applicationsContainer}"></div>
          </div>
          <div class="mt-9 mb-3">
            <button 
              id="${htmlIds.googleLogin}"
              class="flex items-center justify-center p-3 border rounded-lg transition hover:bg-gray-200 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
            ><img class="h-8 mr-2" src="/google-logo.svg" />Sign in with google</button>
          </div>
          <div class="border-b w-96 my-3"></div>
          <form class="w-2/3 my-3 mb-6" id="${htmlIds.loginForm}" data-cy="ula-login-form" novalidate>
            ${this.renderFormField('email', 'Email', 'email')}
            ${this.renderFormField('password', 'Password', 'password')}
            <div class="mt-3 text-right">
              <button
                type="submit"
                data-cy="ula-submit-login-btn"
                class="justify-center items-center rounded-md p-3 border text-sm font-semibold transition hover:bg-gray-200 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
              >Sign in</button>
            </div>
          </form>
        </div>
      </div>
    `;
  }

  private renderFormField(
    id: keyof FormData,
    label: string,
    type: string,
  ): string {
    return `
      <div class="${id === 'password' ? 'mt-1' : ''}">
        <label
          for="${id}"
          class="block text-sm font-medium leading-6 text-gray-900"
        >${label}</label>
        <input
          id="${id}"
          data-cy="ula-${id}"
          name="${id}"
          type="${type}"
          autocomplete="${type === 'password' ? 'current-password' : id}"
          class="py-1.5 px-2 block w-full rounded ring-1 ring-inset ring-gray-300 focus:outline-gray-900"
        />
        <div id="${id}-error" class="transition-all text-sm text-red-600 overflow-hidden opacity-0">&nbsp;</div>
      </div>
    `;
  }

  public async render(container: HTMLDivElement): Promise<void> {
    const configs = await this.configService.getFirebaseConfigs();
    container.innerHTML = this.renderLoginForm(configs);
    this.setupOrganizationListeners();
    await this.renderApps();
  }

  public validateForm(formData: FormData): void {
    this.validateFormData(formData);
  }
}
