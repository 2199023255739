import { ApplicationManyResponseDto } from '../types/ApplicationManyResponseDto';
import { FirebaseConfigResponse } from '../types/FirebaseConfigResponse';
import { getConfig } from '../config';
import { ConfigDto } from '../types/ConfigDto';
import { LoginToAGWResponse } from '../types/common';
import { CustomTokenResponse } from '../types/CustomTokenResponse';
import type { ConfigService } from './configService';
import { ApiError } from '../errors/ApiError';

export class ApiService {
  private static instance: ApiService;
  private readonly baseUrl: string = getConfig().TF_AGW_URL;
  private readonly defaultHeaders: HeadersInit = {
    'Content-Type': 'application/json',
  };
  private static readonly ALLOWED_TENANTS = ['TF', 'TIO', 'RTSG'] as const;

  private constructor(private readonly configService: ConfigService) {}

  public static initialize(configService: ConfigService): void {
    if (!ApiService.instance) {
      ApiService.instance = new ApiService(configService);
    }
  }

  public static getInstance(): ApiService {
    if (!ApiService.instance) {
      throw new ApiError(
        'ApiService must be initialized with ConfigService first',
      );
    }
    return ApiService.instance;
  }

  // Authentication endpoints
  public async loginToAGW(idToken: string): Promise<LoginToAGWResponse> {
    const organizationCode = this.configService.getOrganizationCode();
    const response = await this.post<{
      data: Omit<LoginToAGWResponse, 'token' | 'tenant'>;
    }>(
      `/${organizationCode}/auth/login`,
      { idToken },
      {
        credentials: 'include',
        headers: { 'tfpl-id-token': idToken },
      },
    );

    return {
      ...response.data,
      token: idToken,
      tenant: organizationCode,
    };
  }

  public async createCustomToken(
    idToken: string,
  ): Promise<CustomTokenResponse> {
    return this.post<CustomTokenResponse>(
      `/${this.configService.getOrganizationCode()}/auth/custom-token`,
      { idToken },
      { credentials: 'include' },
    );
  }

  public async getCustomToken(): Promise<CustomTokenResponse> {
    return this.get<CustomTokenResponse>(
      `/${this.configService.getOrganizationCode()}/auth/custom-token`,
      { credentials: 'include' },
    );
  }

  // Configuration endpoints
  public async getFirebaseConfig(): Promise<ConfigDto[]> {
    const response = await this.get<FirebaseConfigResponse>(
      '/auth/firebase-config',
    );
    return response.data.filter((config) =>
      ApiService.ALLOWED_TENANTS.includes(
        config.organization.code as (typeof ApiService.ALLOWED_TENANTS)[number],
      ),
    );
  }

  public async getApplicationConfig(): Promise<
    ApplicationManyResponseDto['data']
  > {
    const response = await this.get<ApplicationManyResponseDto>('/application');
    return response.data;
  }

  // Cookie management
  public async getCookie(query: string = ''): Promise<string | null> {
    return this.get<string>(`/cookies${query}`, { credentials: 'include' });
  }

  // Private HTTP methods
  private async get<T>(path: string, options?: RequestInit): Promise<T> {
    return this.fetch<T>(path, {
      ...options,
      method: 'GET',
    });
  }

  private async post<T>(
    path: string,
    body: unknown,
    options?: RequestInit,
  ): Promise<T> {
    return this.fetch<T>(path, {
      ...options,
      method: 'POST',
      body: JSON.stringify(body),
    });
  }

  private async fetch<T>(path: string, options?: RequestInit): Promise<T> {
    try {
      const response = await fetch(`${this.baseUrl}${path}`, {
        mode: 'cors',
        ...options,
        headers: {
          ...this.defaultHeaders,
          ...options?.headers,
        },
      });

      if (!response.ok) {
        throw new ApiError(
          `HTTP error: ${response.statusText}`,
          response.status,
        );
      }

      return response.json();
    } catch (error) {
      if (error instanceof ApiError) {
        throw error;
      }
      throw new ApiError('Network request failed', undefined, error);
    }
  }
}
