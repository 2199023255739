import Notify from 'simple-notify';
import 'simple-notify/dist/simple-notify.css';

interface ToastOptions {
  effect?: 'fade' | 'slide';
  speed?: number;
  showIcon?: boolean;
  showCloseButton?: boolean;
  autoclose?: boolean;
  autotimeout?: number;
  notificationsGap?: number;
  notificationsPadding?: number;
  position?: 'right top' | 'right bottom' | 'left top' | 'left bottom';
  status?: 'success' | 'error' | 'warning' | 'info';
  title?: string;
  text?: string;
}

const DEFAULT_TOAST_OPTIONS: ToastOptions = {
  effect: 'fade',
  speed: 300,
  showIcon: true,
  showCloseButton: true,
  autoclose: true,
  autotimeout: 6000,
  notificationsGap: 20,
  notificationsPadding: 20,
  position: 'right top',
};

export class ToastService {
  private static instance: ToastService;

  private constructor() {}

  public static getInstance(): ToastService {
    if (!ToastService.instance) {
      ToastService.instance = new ToastService();
    }
    return ToastService.instance;
  }

  public showErrorToast(text: string, options: ToastOptions = {}): void {
    this.showToast({
      status: 'error',
      title: 'Error',
      text,
      ...options,
    });
  }

  public showSuccessToast(text: string, options: ToastOptions = {}): void {
    this.showToast({
      status: 'success',
      title: 'Success',
      text,
      ...options,
    });
  }

  public showWarningToast(text: string, options: ToastOptions = {}): void {
    this.showToast({
      status: 'warning',
      title: 'Warning',
      text,
      ...options,
    });
  }

  public showInfoToast(text: string, options: ToastOptions = {}): void {
    this.showToast({
      status: 'info',
      title: 'Info',
      text,
      ...options,
    });
  }

  private showToast(options: ToastOptions = {}): void {
    new Notify({
      ...DEFAULT_TOAST_OPTIONS,
      ...options,
    });
  }
}
